import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from "@pages/dashboard/dashboard.component";
import {AuthGuard} from "@core/guards/auth.guard";


const routes: Routes = [
  {path: '', redirectTo: '', pathMatch: 'full', canActivate: [AuthGuard]},
  {path: '', component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'auth', loadChildren: () => import('./pages/user-pages/user-pages.module').then(m => m.UserPagesModule)},
  {path: 'ads', canActivate: [AuthGuard], loadChildren: () => import('./pages/ad/ads.module').then(m => m.AdsModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
