export class UserModel {
  ID?: number;
  display_name?: string;
  imageURL?: string;
  phone?: string
  phone_verification?: number;
  user_email?: string;
  user_login?: string;
  user_nicename?: string;
  expires_in?: number;
  token_type?: string;
  access_token?: string;
  is_admin?: boolean;

  constructor({data}: { data: any }) {
    const userData = data['data']['user'];
    this.ID = userData['ID'];
    this.access_token = data['access_token'];
    this.user_email = userData['user_email'];
    this.token_type = data['token_type'];
    this.expires_in = data  ['expires_in'];
    this.user_nicename = userData['user_nicename'];
    this.user_login = userData['user_login'];
    this.display_name = userData['display_name'];
    this.phone_verification = userData['phone_verification'];
    this.imageURL = userData['imageURL'];
    this.is_admin = data['data']['is_admin'];
  }
}
