export const environment = {
  production: false,
  apiUrl: 'https://api.dev.damaz.zaher-tech.com/api/v7/',
  Purchase_Code: '1',
  Custom_Security: '2',
  request_origin: 'android',
  Accept_Language: 'en',
  appSecret: 'random',
  platform: 'web',
};


