import {Inject, Injectable, InjectionToken, PLATFORM_ID} from '@angular/core'
import {isPlatformBrowser} from '@angular/common'
import {StorageKeysEnum} from '../enum/storage-keys.enum';

function getLocalStorage() {
  return localStorage
}

@Injectable({providedIn: 'root'})
export class LocalStorageRefService {

  // tslint:disable-next-line:ban-types
  constructor(@Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {
  }

  get localStorage() {
    if (isPlatformBrowser(this.platformId)) {
      return getLocalStorage()
    } else {
      return null;
    }
  }

  setData(key: StorageKeysEnum, data: string) {
    this.localStorage?.setItem(key, data)
  }

  getData(key: StorageKeysEnum): any {
    return this.localStorage?.getItem(key)
  }

  removeData(key: string) {
    this.localStorage?.removeItem(key)
  }
}
