import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, Subject, throwError} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {environment} from "@env";
import {AuthService} from "@core/services/auth.service";

const API_URL = environment.apiUrl;

@Injectable({providedIn: 'root'})
export class AuthInterceptor implements HttpInterceptor {
  refreshTokenInProgress = false;
  tokenRefreshedSource = new Subject();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(private injector: Injector,
              private userService: AuthService,
              private router: Router,
  ) {
  }

  appendApiUrl(request: HttpRequest<any>) {
    if (!request.url.endsWith('.json')) {
      request = request.clone({url: API_URL + request.url});
    }
    return request;
  }


  addAuthHeader({request}: { request: any }): any {
    if (this.userService.currentUser) {
      const userToken = this.userService.currentUser.access_token;
      return request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + userToken,
          'Purchase-Code': environment.Purchase_Code,
          'Custom-Security': environment.Custom_Security,
          'Content-Type': 'application/json',
          'request-origin': environment.request_origin,
          'Accept-Language': environment.Accept_Language,
          Accept: 'application/json',
        }
      });
    } else {
      return request.clone({
        setHeaders: {
          'Purchase-Code': environment.Purchase_Code,
          'Custom-Security': environment.Custom_Security,
          'Content-Type': 'application/json',
          'request-origin': environment.request_origin,
          'Accept-Language': environment.Accept_Language,
          Accept: 'application/json',
        }
      });
    }
  }

  refreshToken(): Observable<any> {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;

      return this.userService.getNewAccessToken().pipe(
        tap(() => {
          this.refreshTokenInProgress = false;
          this.tokenRefreshedSource.next();
        }),
        catchError(() => {
          this.refreshTokenInProgress = false;
          this.logout();
          return 'error';
        }));
    }
  }

  logout(): any {
    this.userService.logout();
    this.router.navigate(['login']);
  }

  handleResponseError({error, request, next}: { error: any, request?: any, next?: any }): any {

    if (error.status === 422) {
      return throwError(error);
    }

    // Invalid token error
    else if (error.status === 401) {
      return this.refreshToken().pipe(
        switchMap(() => {
          request = this.addAuthHeader({request: request});
          return next.handle(request);
        }),
        catchError(e => {
          if (e.status !== 401) {
            return this.handleResponseError({error: e});
          } else {
            this.logout();
          }
        }));
    } else if (error.status === 403) {
      return throwError(error);
    } else if (error.status === 429) {
      return throwError(error);
    } else if (error.status === 500) {
      return throwError(error);
    }

    // Maintenance error
    else if (error.status === 503) {
      return throwError(error);

    }

    return throwError(error);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {

    request = this.addAuthHeader({request: request});
    request = this.appendApiUrl(request);

    // Handle response
    return next.handle(request).pipe(map(value => {
      return value;
    }), catchError(error => {
      return this.handleResponseError({error: error, request: request, next: next});
    }));
  }
}


