import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import {LocalStorageRefService} from './local-storage-ref.service';
import {StorageKeysEnum} from '../enum/storage-keys.enum';
import {UserModel} from '../models/user.model';
import {ResponseModel} from '../models/response.model';
import {Token} from '@angular/compiler';

@Injectable({providedIn: 'root'})
export class AuthService {

  // tslint:disable-next-line:variable-name
  profile_default = '../../../../assets/images/profile-default.png';

  user$: BehaviorSubject<UserModel | null> =
    new BehaviorSubject<UserModel | null>(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private localStorageRefService: LocalStorageRefService
  ) {
    this.user$.next(this.loadFromStorage());
  }

  private loadFromStorage() {
    return JSON.parse(
      this.localStorageRefService.getData(StorageKeysEnum.USER)
    ) as UserModel;
  }

  public get currentUser() {
    return this.user$.value;
  }

  login(username: string, password: string) {
    const body = {
      username,
      password,
    };
    return this.http.post<ResponseModel<UserModel>>('adminLogin', body, {}).pipe(
      tap(async (response) => {
        this.handleAuthentication({token: response});
      })
    );
  }

  async unAuth() {
    this.localStorageRefService.removeData(StorageKeysEnum.USER);
    this.user$.next(null);
    await this.router.navigate(['auth/login']);
  }

  isLoggedIn() {
    return this.user$.value !== null;
  }

  isAdmin() {
    return this.user$.value !== null && this.user$.value.is_admin;
  }


  private handleAuthentication({token}: { token: any }) {

    const user = new UserModel({data: token});
    this.user$.next(user);
    this.localStorageRefService.setData(
      StorageKeysEnum.USER,
      JSON.stringify(user)
    );
  }


  logout(): void {
    this.localStorageRefService.removeData(StorageKeysEnum.USER)
    this.user$.next(null);
    this.router.navigate(['auth/login']);
  }

  updateTokenUser(token: string): void {
    this.user$.value!.access_token = token;
  }

  getNewAccessToken(): any {
    const headers = new HttpHeaders();
    headers.append('Authorization', 'Bearer ' + this.currentUser!.access_token);

    return this.http.post<Token>('refresh',
      null, {headers}).pipe(
      tap((response: Token) => {
          // @ts-ignore
          this.updateTokenUser(response.access_token);
        }
      ));
  }


}
